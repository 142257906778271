import { StateCreator } from 'zustand';
import { IProfileSetting } from '@/types/Authentication';
import { IProfileSlice } from './Interface';

export const profileSlice: StateCreator<IProfileSlice> = (set) => ({
  //state initializing
  
  userProfile: {
    email: '',
    mobile: '',
    username: '',
    tow_factor: false,
    user_exist: false,
    wallet: '',
    tow_factor_secret: '',
  },
  //state handler functions
  setUserProfile: (userProfile: IProfileSetting) => {
    set({
      userProfile: userProfile,
    });
  },

});
