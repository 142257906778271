import React, { ReactNode } from 'react';
import cn from 'classnames';
import styles from './Typography.module.scss';

interface IProps {
  children: ReactNode;
  className?: string;
  size?:
    | 's40ToS24'
    | 's36ToS22'
    | 's32ToS20'
    | 's28ToS18'
    | 's28ToS15'
    | 's26ToS16'
    | 's24ToS16'
    | 's22ToS14'
    | 's22ToS16'
    | 's20ToS16'
    | 's20ToS14'
    | 's18ToS14'
    | 's16ToS14'
    | 's16ToS12'
    | 's14ToS12'
    | 's12ToS12';
  isItalic?: boolean;
  hasNoWrap?: boolean;
  isBold?: boolean;
  Component?: any;
  style?: any;
  onClick?: (e: React.MouseEvent) => void;
  bulletNode?: string | ReactNode;
  text?: string;
}

const isReactNode = (value: any): value is ReactNode => React.isValidElement(value) || typeof value !== 'string';

const Typography = (props: IProps) => {
  const { children, className, size, isItalic, hasNoWrap, Component = 'span', isBold, onClick, style, bulletNode, text = '' } = props;

  const rootClassName = cn(
    size && styles[size],
    {
      [styles.noWrap]: hasNoWrap,
      ['italic']: isItalic,
      ['font-bold']: isBold,
      'flex items-start': bulletNode,
    },
    className,
  );

  return (
    <Component suppressHydrationWarning style={style} onClick={onClick} className={rootClassName}>
      {bulletNode && (
        <div className='flex justify-start pt-[8px] h-full w-auto pe-[8px]'>{isReactNode(bulletNode) ? bulletNode : <span>{bulletNode}</span>}</div>
      )}
      {children} {!children && text}
    </Component>
  );
};

export default Typography;
