/* eslint-disable sonarjs/no-duplicate-string */
import api from '@/api/services/index';
import apiInventory from '@/api/services/inventory';
import LeaderBoardModal from '@/components/Achievements/CampaignCards/components/LeaderBoardModal/LeaderBoardModal';
import ConnectLoginOrWalletModal from '@/components/Common/ConnectLoginOrWalletModal';
import FooterNew from '@/components/Common/FooterNew';
import HeadPage from '@/components/Common/Head';
import ConfirmModal from '@/components/Common/Modals/ConfirmModal/ConfirmModal';
import { useResponsive } from '@/hooks';
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';
import useCommandReward from '@/hooks/useCommandReward';
import useCraftReward from '@/hooks/useCraftReward';
import { passportInstance } from '@/lib/immutable';
import { _getProfileSetting, _sendVerifyEmail } from '@/service/auth/auth';
import { EAuthVarient } from '@/types/Authentication';
import { detectWebGLSupport } from '@/utils/isWebglSupported';
import { useStore as use2DGameStore } from '@/zustand/store/2dGameSore';
import { useStore } from '@/zustand/store/store';
import axios from 'axios';
import classNames from 'classnames';
import { signOut, useSession } from 'next-auth/react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAccount, useAccountEffect, useDisconnect, useSignMessage } from 'wagmi';
import Header from '../Header';
import LevelModal from '../LevelModal';
import LoadingPage from '../LoadingPage';
import MobileMenu from '../MobileMenu/MobileMenu';
import styles from './LayoutPage.module.scss';
import { usePageLayout } from './usePageLayout';
import { isItemInArray } from '@/utils/isItemInArray';
import GalaxyModal from '../GalaxyModal';

const CelebrationModal = dynamic(() => import('@/components/CelebrationModal'), { ssr: false });

const WalletModal = dynamic(() => import('../Modals/WalletModal'), { ssr: false });
const AuthModal = dynamic(() => import('../AuthModal/AuthModal'), { ssr: false });
const TopBanner = dynamic(() => import('@/components/Home/TopBanner'), { ssr: false });

export interface ILayoutPageProps {
  children: ReactNode;
  metaTags: {
    title: string;
    description: string;
    imageUrl: string;
    isHome: boolean;
  };
}
// const DynamicHeader = dynamic(() => import('../Header'), { ssr: false });

let state: 'login' | 'logout';

// eslint-disable-next-line sonarjs/cognitive-complexity
const LayoutPage = (props: ILayoutPageProps) => {
  const [activeBurgerMenu, setActiveBurgerMenu] = useState(false);
  const { isMobile } = useResponsive();
  const { clearData, setWidthPercent } = use2DGameStore();
  const session = useSession();
  const [isCallCheckAgreement, setIsCallCheckAgreement] = useState(false);

  const {
    isTopBannerOpen,
    authModalVarient,
    isLoading,
    isModalOpen,
    setAuthModalVarient,
    setIsModalOpen,
    seasonsVideoPlayerModal,
    setUserData,
    setWalletAddress,
    containerStyle,
    layoutStyle,
    rootStyle,
    leaderboardModal,
    openConnectLoginOrWalletModal,
    setOpenConnectLoginOrWalletModal,
    setIsShowToast,
    setIsSoldOut,
    isLogOutModal,
    setLogOutModal,
    setLogOutAction,
    logOutAction,
    isShowLevelModal,
    setShowRewardsModal,
    showRewardsModal,
    setIsSupportedWebgl,
    commandSocketData: { rewards, rewardsProcessed },
    setRefetchUserData,
  } = useStore();
  const { isLogin } = useAuthStatuses();
  const disconnect = useDisconnect();
  const router = useRouter();
  const { webgl1, webgl2 } = detectWebGLSupport();
  const { children, metaTags } = props;
  const { address, isConnected } = useAccount();
  let itemInArray = false;
  useCommandReward();
  // useCraftReward();

  useEffect(() => {
    if (isLogin) {
      if ((session?.data as any)?.provider === 'immutable') {
        passportInstance.connectEvm();
      }
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin && rewards !== undefined && rewards?.length > 0 && !showRewardsModal && !rewardsProcessed) {
      setShowRewardsModal(true);
      setRefetchUserData(true);
    }
  }, [rewards, isLogin]);

  const { signMessage } = useSignMessage({
    mutation: {
      onSuccess(dataa, variables) {
        //@ts-ignore
        if (session.data?.user?.wallet === null || session.data?.user?.wallet === undefined) {
          session.update({
            user: {
              ...session.data.user,
              wallet: [address],
            },
          });
        } else {
          session.update({
            user: {
              ...session.data.user,
              //@ts-ignore
              wallet: [...session.data?.user?.wallet, address],
            },
          });
        }

        // Verify signature when sign message succeeds
        api
          .verifyWallet({
            message: variables.message as string,
            sign: dataa,
            wallet: address,
          })
          .then((res) => {
            if (!res.data.exchange_status) {
              setIsShowToast({
                isShow: true,
                message: res.data.error_message,
                method: 'error',
              });
              disconnect.disconnect();
            }
          });
      },
      onError(error) {
        console.error(error);
        disconnect.disconnect();
      },
    },
  });
  const [showMoniffestModal, setShowManifestModal] = useState(false);
  const layout = usePageLayout();

  useEffect(() => {
    setIsSupportedWebgl({
      webgl1: webgl1,
      webgl2: webgl2,
    });

    const timer = setTimeout(() => {
      setShowManifestModal(true);
    }, 5000); // 5,000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Clean up the timer when the component unmounts
  }, []);

  const [setting, setSetting] = useState();
  const [walletStatus, setWalletStatus] = useState<'verify' | 'connect' | 'none'>('none');

  const handleActiveBurgerMenu = () => {
    setActiveBurgerMenu(!activeBurgerMenu);
  };

  useEffect(() => {
    setIsSoldOut(true);
  }, []);

  useEffect(() => {
    if (isLogin) {
      //@ts-ignore
      if (typeof session?.data?.user?.wallet === 'object') {
      } else {
        apiInventory
          .allWallet()
          .then((res) => {
            session.update({
              user: {
                ...session.data.user,
                wallet: res.data.wallets,
              },
            });
          })
          .catch((error) => {});
      }
      setIsCallCheckAgreement(true);
    }
  }, [isLogin]);

  useEffect(() => {
    if (typeof router.query?.code === 'string' && router.query?.code?.length <= 8 && router.query?.code?.length >= 6) {
      //@ts-ignore
      localStorage.setItem('invite-code', router.query.code);
      sessionStorage.setItem('invite-code', String(router.query.code));
      if (session.status !== 'loading' && (session.data === undefined || session.data == null)) {
        setAuthModalVarient(EAuthVarient.SIGN_UP);
      }
    } else {
      if (router.query.code !== undefined && router.query.email !== undefined) {
        _sendVerifyEmail({
          code: router.query.code,
          email: router.query.email,
        }).then((res) => {
          if (res.status) {
            const { code, ...restQuery } = router.query;
            router.replace(
              {
                pathname: router.pathname,
                query: restQuery,
              },
              undefined,
              { shallow: true },
            );
            // router.replace('/command-center?isonboarding=step-two', undefined, { shallow: false });
            //@ts-ignore
            localStorage.setItem('invite-code', '');
            sessionStorage.setItem('invite-code', '');
            setAuthModalVarient(EAuthVarient.SIGN_IN);
          }
        });
      }
    }

    if (session.status !== 'loading' && session.data !== undefined && session.data !== null) {
      setAuthModalVarient(EAuthVarient.null);
    }
  }, [router, session]);

  let isUserVerified;
  useEffect(() => {
    isUserVerified = localStorage.getItem('isUserVerified');
    let accessTokenKitblock = localStorage.getItem('kitblockAccessToken');
    //@ts-ignore
    // if (isLogin && accessTokenKitblock == undefined && session?.data !== null) {
    //   axios({
    //     method: 'get',
    //     url: 'https://builder-service.kitblock.io/user/credential/landrocker',
    //     headers: {
    //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //       //@ts-ignore
    //       Authorization: session.data.accessToken,
    //     },
    //   }).then((res) => {
    //     localStorage.setItem('kitblockAccessToken', res.data.data.access_token);
    //     localStorage.setItem('kitblockRefreshToken', res.data.data.refresh_token);
    //   });
    // }

    //@ts-ignore
    if (session?.data?.user?.hasTwoFA && session.data?.valid_token === false && isUserVerified === 'false') {
      setAuthModalVarient(EAuthVarient.VERIFYCODE);
    }
  }, [session, isUserVerified]);

  useEffect(() => {
    //@ts-ignore
    if (router.query.error !== undefined && router.query.error == 401) {
      if (session.status !== 'loading' && session.status !== 'authenticated') {
        setAuthModalVarient(EAuthVarient.SIGN_IN);
      } else {
        if (session.status !== 'loading' && session.status == 'authenticated') {
          router.replace(router.pathname, undefined, { shallow: true });
        }
      }
    }
  }, [router, session]);

  //test
  useEffect(() => {
    if (session.data !== null && session.data?.user !== undefined) {
      //@ts-ignore
      itemInArray = isItemInArray(session.data?.user?.wallet, address);
    }
    if (isLogin) {
      // @ts-ignore
      _getProfileSetting(session?.data?.accessToken).then((res) => {
        // @ts-ignore
        setSetting(res);
        // @ts-ignore
        setUserData(res);
      });
    }
    // if (
    //   session.status !== 'loading' &&
    //   session?.data?.user &&
    //   //@ts-ignore
    //   itemInArray &&
    //   isConnected &&
    //   //@ts-ignore
    //   session?.data?.user?.wallet.length > 0
    // ) {
    //   setIsShowToast({
    //     isShow: true,
    //     message: 'A previous wallet was linked to this account. Consider checking for assets on the previous wallet.',
    //     method: 'error',
    //   });
    //   disconnect.disconnect();
    // }

    //@ts-ignore
    if (session.data?.error === 'RefreshAccessTokenError') {
      setAuthModalVarient(EAuthVarient.SIGN_IN); // Force sign in to hopefully resolve error
      disconnect.disconnect();
      setWalletAddress(undefined);
      localStorage.setItem('isUserVerified', 'false');
      localStorage.removeItem('kitblockAccessToken');
      localStorage.removeItem('kitblockRefreshToken');
      router.replace(router.asPath);
      signOut({ redirect: false });
      clearData();
    }
    // @ts-ignore
    if (session.data?.valid_token === false && session.data?.user?.hasTwoFA) {
      localStorage.setItem('isUserVerified', 'false');
    } else {
      // @ts-ignore
      if (session.data?.valid_token === true && session.data?.user?.hasTwoFA) {
        localStorage.setItem('isUserVerified', 'true');
      }
    }
  }, [session]);

  // const getLibrary = (provider) => {
  //   const library = new Web3Provider(provider);
  //   library.pollingInterval = 12000;
  //   return library;
  // };

  useEffect(() => {
    if (setting !== undefined && session.status !== 'loading' && session.data !== null) {
      // @ts-ignore
      if (setting.wallet == 'nil' && isConnected) {
        setWalletStatus('verify');
      } else if (!isConnected) {
        setWalletStatus('connect');
      } else {
        setWalletStatus('none');
      }
    }
    if (isConnected) {
      setIsModalOpen('wallet', false);
    }
  }, [setting, isConnected]);

  useEffect(() => {
    //@ts-ignore
    if (address !== undefined && address !== setting?.wallet) {
      setWalletStatus('verify');
    }
  }, [setting]);

  useAccountEffect({
    onConnect(res) {},
    onDisconnect() {
      toast.dark('Wallet is Disconnected.', {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: 'gray',
          color: 'white',
        },
      });
    },
  });

  useEffect(() => {
    if (session.data !== null && session.data?.user !== undefined) {
      //@ts-ignore
      itemInArray = isItemInArray(session.data?.user?.wallet, address);
    }
    if (isLogin && address) {
      //@ts-ignore
      if (itemInArray) {
      } else {
        if (isLogin) {
          api.getMessageforSign({ wallet: address }).then((ress) => {
            const formData = new FormData();
            formData.append(
              'message',
              `Welcome to LandRocker\nPlease sign to verify your wallet. This is not a transaction, and no gas fees will be charged.\n\nWallet address:\n${address}\n\n${ress.data.message}`,
            );
            const message = formData.get('message');
            // @ts-ignore
            signMessage({ message });
          });
        }
      }
    }
  }, [address, isLogin]);

  const handleLogout = async () => {
    try {
      await passportInstance.logout();
      window.location.href = '/'; // Redirect to the home page after logout
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    if (router?.query?.from === 'telegram') {
      if (isLogin) {
        api.syncTelegramAccount({
          app_name: 'telegram',
          app_token: router?.query?.token as string,
        });
      } else {
        setAuthModalVarient(EAuthVarient.SIGN_IN);
      }
    }
  }, [isLogin]);

  // const logoutHandler = async () => {
  //   setLogOutAction('loading');
  //   handleLogout();
  //   await api.unSign().then(() => {
  //     // @ts-ignore
  //     // if (session.data.provider === 'immutable') {
  //     //   handleLogout();
  //     // }
  //     signOut().then(() => {
  //       setLogOutAction('');
  //       clearData();
  //       // @ts-ignore
  //       // if (session.data.provider === 'immutable') {
  //       //   alert('hello');
  //       //   passportInstance.logout();
  //       // }
  //     });
  //   });

  //   disconnect.disconnect();
  //   setWalletAddress(undefined);
  //   setWidthPercent(0);
  //   localStorage.setItem('isUserVerified', 'false');
  //   localStorage.removeItem('kitblockAccessToken');
  //   localStorage.removeItem('kitblockRefreshToken');

  //   // router.replace(router.asPath);
  // };

  const logoutHandler = async () => {
    setLogOutAction('loading');
    // @ts-ignore
    if (session.data.provider === 'immutable') {
      handleLogout();
    }
    signOut().then(() => {
      setLogOutAction('');
      clearData();
    });

    // await api.unSign().then(() => {
    // });

    disconnect.disconnect();
    setWalletAddress(undefined);
    setWidthPercent(0);
    localStorage.setItem('isUserVerified', 'false');
    localStorage.removeItem('kitblockAccessToken');
    localStorage.removeItem('kitblockRefreshToken');
  };
  const isGameDownloadRoute = router.pathname === '/game';

  useEffect(() => {
    if (session.data) {
      // User is logged in
      try {
        if (state != 'login') {
          // @ts-ignore
          window.dataLayer = window.dataLayer || [];
          // @ts-ignore
          window.dataLayer.push({
            event: 'login_success',
          });

          // @ts-ignore
          // if (session.data.newUser2) {
          //   // @ts-ignore
          //   window.dataLayer.push({
          //     event: 'signup_success',
          //   });
          // }
          state = 'login';
          session.update({
            ...session.data,
            user: {
              ...session.data.user,
              new_user: false,
            },
          });
        }
      } catch (error) {
        console.error('Error pushing to dataLayer:', error);
      }
    } else {
      // User is logged out
      try {
        if (state === 'login') {
          // @ts-ignore
          window.dataLayer = window.dataLayer || [];
          // @ts-ignore
          window.dataLayer.push({
            event: 'logout_success',
          });
          state = 'logout';
        }
      } catch (error) {
        console.error('Error pushing to dataLayer:', error);
      }
    }
  }, [session]);

  return (
    <div className='flex flex-col justify-center items-center'>
      {metaTags !== undefined && (
        <HeadPage title={metaTags.title} description={metaTags.description} imageUrl={metaTags.imageUrl} isHome={metaTags.isHome} />
      )}
      {/* <Web3ReactProvider getLibrary={getLibrary}> */}
      <div
        className={classNames(styles.root, rootStyle === '' ? styles.rootSecondaryStyle : rootStyle, {
          // Remove max-width if on the `/game/download` route
          'max-w-none': isGameDownloadRoute,
          'max-w-[1920px]': !isGameDownloadRoute,
        })}
      >
        {/* {router.pathname == '/command-center' && ( */}
        {/* <div className={`fixed right-0 z-40 lg:hidden ${isTopBannerOpen ? (isMobile ? 'top-[2px]' : 'top-[4px]') : 'top-[2px]'} mr-2`}>
            <Hamburger toggled={activeBurgerMenu} toggle={handleActiveBurgerMenu} color={`${activeBurgerMenu ? '#29d7df' : 'white'}`} size={24} />
          </div> */}
        {/* )} */}
        {activeBurgerMenu && <MobileMenu closeMobileMenu={() => setActiveBurgerMenu(false)} />}
        <div>
          <Header toggled={activeBurgerMenu} toggle={handleActiveBurgerMenu} />
        </div>
        <TopBanner />
        {/* {router.pathname !== '/command-center' && (
              <div className={styles.menu}>
                <div className='flex h-[calc(100vh-26px)] w-full items-center'>
                  <div className={styles.boxMenu}>
                    <MenuBar />
                  </div>
                </div>
              </div>
            )} */}
        <div className={classNames('relative w-full')}>
          <div
            className={classNames(
              layout === 'isWide' || layout === 'isNarrow' ? 'pt-[100px] md:pt-[110px] lg:pt-[120px] ' : 'pt-14',
              layout === 'isWide' ? styles.rootWide : layout === 'isNarrow' ? styles.rootCompact : '',

              // 'mt-[140px] md:mt-[110px] lg:mt-[80px] xl:mt-[80px]',
              // `${router.pathname == '/official-verify' ? 'mt-[140px] md:mt-[110px] lg:mt-[80px] xl:mt-[80px]' : 'mt-[0px]'}`,

              containerStyle === '' ? styles.containerChildrenSecondaryStyle : containerStyle,
            )}
          >
            <div className={router.pathname !== '/command-center' ? 'mt-11 md:mt-14 xl:mt-16' : 'lg:mt-10'}>{children}</div>
          </div>
        </div>
      </div>
      {/* </Web3ReactProvider> */}
      {isLoading && <LoadingPage />}
      <WalletModal />
      {authModalVarient !== EAuthVarient.null && <AuthModal />}
      {leaderboardModal && <LeaderBoardModal />}
      {/* <ExpireKitblockToken /> */}
      {/* @ts-ignore */}
      {/* {session.data?.accept_agreement !== undefined && showMoniffestModal && <ManifestModal open={!session.data?.accept_agreement} />} */}
      {isShowLevelModal && <LevelModal />}
      {/* <GalaxyModal /> */}
      <ConnectLoginOrWalletModal opened={openConnectLoginOrWalletModal} setOpened={setOpenConnectLoginOrWalletModal} />
      <ConfirmModal
        title='LOG OUT'
        confirmTypes='error'
        description='Are you sure you want to log out?'
        opened={isLogOutModal}
        cta={() => {
          logoutHandler();
        }}
        ctaButtonText='Yes, Log Out'
        closeAction={() => {
          setLogOutModal(false);
        }}
        isLoading={logOutAction === 'loading'}
        closeButtonText='Stay Logged In'
      />
      <CelebrationModal />
      <FooterNew />
    </div>
  );
};

export default LayoutPage;
