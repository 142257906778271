import { create } from 'zustand';
import { persist } from 'zustand/middleware'; // Import persist middleware
import {
  achievementsSlice,
  authSlice,
  blueprintSlice,
  commonSlice,
  gameRoadMapSlice,
  inventorySlice,
  marketplaceSlice,
  miningSlice,
  modalSlice,
  profileSlice,
  stakeSlice,
  swapSlice,
  tasksSlice,
  userSlice,
} from './slices';
import {
  IAchievementsSlice,
  IAuthSlice,
  IBlueprintDetailSlice,
  ICommonSlice,
  IGameRoadMap,
  IInventorySlice,
  IMarketplaceSlice,
  IMiningSlice,
  IModalSlice,
  IStakeSliceProps,
  ISwapSlice,
  ITasksSlice,
  IUserSlice,
  IProfileSlice,
} from './slices/Interface';

export type IUseStore = IUserSlice &
  IMiningSlice &
  IAuthSlice &
  IAchievementsSlice &
  ICommonSlice &
  IModalSlice &
  IMarketplaceSlice &
  ISwapSlice &
  IStakeSliceProps &
  IBlueprintDetailSlice &
  IGameRoadMap &
  IInventorySlice &
  ITasksSlice &
  IProfileSlice;

export const useStore = create<IUseStore>()(
  persist(
    (...a) => ({
      ...userSlice(...a),
      ...profileSlice(...a),
      ...swapSlice(...a),
      ...achievementsSlice(...a),
      ...gameRoadMapSlice(...a),
      ...miningSlice(...a),
      ...authSlice(...a),
      ...commonSlice(...a),
      ...modalSlice(...a),
      ...marketplaceSlice(...a),
      ...blueprintSlice(...a),
      ...stakeSlice(...a),
      ...inventorySlice(...a),
      ...tasksSlice(...a),
    }),
    {
      name: 'zustand-storage', // Name of the storage key (you can change this to any string)
      partialize: (state) => ({
        user: state.userData,
        liteGameId: state.liteGameId,
        liteGameResult: state.liteGameResult,
        liteGameWinnerName: state.liteGameWinnerName,
        // Add any other slices you want to persist
      }),
      // Optionally, you can specify the storage type:
      // storage: createJSONStorage(() => sessionStorage), // For sessionStorage instead of localStorage
    },
  ),
);
