import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import liteApi from '@/api/services/lite-game';
import { Copy } from '@/components/Svg';
import AvatarLoginSvg from '@/components/Svg/AvatarLoginSvg';
import { EAuthVarient } from '@/types/Authentication';
import { numberSeparator } from '@/utils/numberSeparator';
import { useStore } from '@/zustand/store/store';
import CustomSkeleton from '../CustomSkeleton';
import MailBoxLink from './MailBoxLink';
import styles from './Profile.module.scss';
import use2DUserData from '@/hooks/Achievements/use2DUserData';
import api from '@/api/services/auth';

interface IProfile {
  data: {
    email: string;
    message: string;
    name: string;
    referral: string;
    wallet: string;
    nameProvider: string;
  };
}

const Profile = (props: IProfile) => {
  const { data: session, status } = useSession();
  const { data } = props;
  const { user2DData: user, isLoading: isUserLoading } = use2DUserData();
  const [referalData, setReferalData] = useState({
    status: '',
    user_referral_code: '',
    user_referral_link: '',
  });

  const { setAuthModalVarient, setLogOutModal,userProfile, setUserProfile } = useStore();
  const getReferalData = async () => {
    try {
      const response = await liteApi.getUserReferalData();
      if (response.status === 200) {
        const { data: refData } = response;
        if (refData.status) {
          setReferalData(refData);
        }
      } else {
      }
    } catch (e) {}
  };

  const getUserInfo = async () => {
    
    // @ts-ignore
    if (session?.accessToken) {
     
      try {
        // @ts-ignore
        const res = await api.getProfileSetting(session.accessToken);
      
        setUserProfile(res.data);
    
      } catch (error) {
        console.error('Error fetching user info:', error);
    
      }
    }
  };

  useEffect(() => {
    getReferalData();
  }, []);
  
  useEffect(() => {

    if (status === 'authenticated') {
      getUserInfo();
    }

  }, [session]);

  const handleCopyClick = (code: string) => {
    if (navigator.clipboard.writeText(code)) {
      toast.success('Copied to clipboard!', {
        position: 'bottom-center',
        autoClose: 2000,
        closeOnClick: true,
        theme: 'dark',
      });
    }
  };

  const totalPoints = user?.experience || 0;

  return (
    <div className={cn(styles.wrapper)}>
      <div className={styles.profile}>
        {data !== undefined && (
          <Link href='/dashboard'>
            <a className={cn(styles.profileInfo, 'rounded-lg transition hover:shadow hover:bg-secondary/10 hover:translate-x-4 ps-1')}>
              <div className={styles.profilePic}>
                <AvatarLoginSvg />
              </div>
              <div className={styles.profileDetails}>
                {/* @ts-ignore */}
                <div className={styles.profileUsername}>Hi, {userProfile?.username ?? session?.user?.name}</div>
                {/* <div className={styles.profileReferral}>Referral ID: {data?.referral}</div> */}
              </div>
            </a>
          </Link>
        )}

        {isUserLoading ? (
          <div className={'!mt-0 rounded-[4px] px-2 py-1'}>
            {/* <div>
              <RewardSvg isBlack className='h-[1.5em] w-auto' />
            </div> */}
            <div>
              <h5 className='flex gap-1 text-[13px] text-[#92A2AF]'>
                Total XP: <CustomSkeleton width={'40px'} height='16px' animate />{' '}
              </h5>
            </div>
          </div>
        ) : (
          <Link href='/command-center'>
            <a className={'!mt-0 rounded-[4px] px-2 py-1'}>
              {/* <div>
                <RewardSvg isBlack className='h-[1.5em] w-auto' />
              </div> */}
              <div>
                <h5 className='flex gap-1 text-[13px] text-[#92A2AF]'>Total XP: {numberSeparator(totalPoints, ',')}</h5>
              </div>
            </a>
          </Link>
        )}
        {referalData && (
          <div
            className='gap-2 border border-[#363843] w-full p-2 rounded-md flex justify-between items-center cursor-pointer'
            onClick={() =>
              handleCopyClick(`Hey there,:clap:
Get ready for an epic adventure! Here’s your exclusive invitation link to join LandRocker. Download the game, mine, explore fascinating landscapes, take on thrilling quests, and unleash your creativity.
Join now and let the fun begin!
${referalData?.user_referral_link}`)
            }
          >
            <div className='flex flex-col items-start text-[14px]'>
              <div className='text-white'>Referral code: {referalData?.user_referral_code}</div>
              <span className='text-[12px]'>Invite your friends and earn LRT rewards!</span>
            </div>
            <div>
              <Copy />
            </div>
          </div>
        )}
      </div>
      <ul>
        {data !== undefined ? (
          <>
            <Link href='/dashboard' passHref>
              <a href='' className={styles.listItem}>
                <li>Dashboard</li>
              </a>
            </Link>
            <Link href='/profile' passHref>
              <a href='' className={styles.listItem}>
                <li>Profile</li>
              </a>
            </Link>
          </>
        ) : (
          <li className={styles.listItem} onClick={() => setAuthModalVarient(EAuthVarient.SIGN_IN)}>
            Login
          </li>
        )}
        {/* @ts-ignore */}
        {session?.status !== 'unauthenticated' && session !== null && (
          <Link href='/mail-box' passHref>
            <a href='' className={styles.listItem}>
              <li>
                <MailBoxLink />
              </li>
            </a>
          </Link>
        )}
        <Link href='/command-center' passHref>
          <a href='' className={styles.listItem}>
            <li>Commands</li>
          </a>
        </Link>
        <Link href='/inventory' passHref>
          <a href='' className={styles.listItem}>
            <li>Inventory</li>
          </a>
        </Link>
        <Link href='/official-verify' passHref>
          <a href='' className={styles.listItem}>
            <li>LandRocker Verify</li>
          </a>
        </Link>
        {data !== undefined ? (
          <li
            className={styles.listItem}
            onClick={() => {
              setLogOutModal(true);
            }}
          >
            Log out
          </li>
        ) : (
          <li className={styles.listItem} onClick={() => setAuthModalVarient(EAuthVarient.SIGN_UP)}>
            Sign Up
          </li>
        )}
      </ul>
    </div>
  );
};

export default Profile;
